import React from "react";
import Carousel from "react-bootstrap/Carousel";
import BannerOne from "../../images/banner-1.png";
import BannerTwo from "../../images/banner-2.png";
import BannerThree from "../../images/banner-3.png";
import "../Banner/Banner.css";
import { Button } from "react-bootstrap";

const Banner = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="banner-img" src={BannerOne} text="First slide" />
        <div className="banner-caption">
          <h3>Providing Excellency</h3>
          <h3 className="mb-5">
            <span className="bannerTextHighlight">since 2022</span>
          </h3>

          <a href="#" className="brand-btn">
            Know More About Us
          </a>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <img className="banner-img" src={BannerTwo} text="Second slide" />
        <div className="banner-caption">
          <h3>Providing Excellency</h3>
          <h3 className="mb-5">
            <span className="bannerTextHighlight">since 2022</span>
          </h3>

          <a href="#" className="brand-btn">
            Know More About Us
          </a>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <img className="banner-img" src={BannerThree} text="Third slide" />
        <div className="banner-caption">
          <h3>Providing Excellency</h3>
          <h3 className="mb-5">
            <span className="bannerTextHighlight">since 2022</span>
          </h3>

          <a href="#" className="brand-btn">
            Know More About Us
          </a>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default Banner;
