import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Icon } from "@iconify/react";

import "../WhyChooseUs/WhyChooseUs.css";

const WhyChooseUs = () => {
  return (
    <section id="why-choose-us" className="section-spacing">
      <Container>
        <div className="section-title">
          <h2 className="text-center">Why Choose Watani Auto?</h2>
          <p className="title-desc-primary text-center">
            How do we compare to others?
          </p>
        </div>
        <Row className="mt-5 text-center justify-content-center">
          <Col className="mb-2" sm={12} md={12} lg={4}>
            <div className="chooseWrap">
              <Icon
                icon="iconamoon:shield-yes-fill"
                className="whyChooseIcon"
              />
              <div className="chooseWrapTitle">
                <h3>Reliable & Satisfying</h3>
                <h3>Services</h3>
              </div>
              <p>
                Our customers receive the best possible service in the
                automotive market
              </p>
            </div>
          </Col>
          <Col className="mb-2" sm={12} md={12} lg={4}>
            <div className="chooseWrap">
            <Icon icon="fluent-mdl2:money" className="whyChooseIcon" />
              <div className="chooseWrapTitle">
                <h3>Multiple Financing</h3>
                <h3>Options</h3>
              </div>
              <p>
                Our mission is to make purchasing a car convenient and reliable by providing multiple financing options and methods
              </p>
            </div>
          </Col>
          <Col className="mb-2" sm={12} md={12} lg={4}>
            <div className="chooseWrap">
              <Icon
                icon="mdi:truck-delivery"
                className="whyChooseIcon"
              />
              <div className="chooseWrapTitle">
                <h3>Fast & Free</h3>
                <h3>Delivery</h3>
              </div>
              <p>
                We deliver your car wherever you are in the kingdom straight to your doorstep
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhyChooseUs;
