import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../About/About.css";

import ceoImage from "../../images/ceo-watani-auto.png";

const About = () => {
  return (
    <section id="about-watani" className="section-spacing">
      <Container>
        <div className="section-title">
          <h2 className="text-center">
            About <span className="title-highlight">Watani Auto</span>
          </h2>
          <p className="title-desc-primary text-center">
            A word from Watani Auto's CEO
          </p>
        </div>
        <Row className="align-items-center">
          <Col md={12} lg={3}>
            <div className="border-shadow-radius">
            <img src={ceoImage} className="img-fluid mx-auto d-block" />
            </div>
            
          </Col>
          <Col md={12} lg={9}>
            <p className="detailPara">
              On behalf of Najeeb Auto Holding Company we extend our sincere
              appreciation to all holding company team member, specially at
              Watani Auto, through this short time they gained the market trust
              from customers and local distributors as well from NHC upper
              management.
            </p>
            <p className="detailPara">
              Watani Auto Team is dedicated and committed to core values that
              made them successful team and supported to this achievement.
            </p>
            <p className="detailPara">
              “We have our dreams, and fortunately, some of them have already
              come true. However, we are confident that the rest will soon
              become a reality as well. With unwavering determination and
              persistence, we are committed to turning our remaining dreams into
              tangible achievements” A. Aziz El Shehri
            </p>

            <h2 className="ceoTitle">Mr. Abdelaziz Al Shehri</h2>
            <h4 className="designation">Chief Executive Officer</h4>
            <h4 className="companyName">Najeeb Auto</h4>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
