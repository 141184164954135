import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AboutIntroBanner from "../../images/about-watani-banner.png";
import VisionMissionBanner from "../../images/vision-mission-banner.jpg";
import Owner from "../../images/owner-watani-auto.jpg";
import GeneralManager from "../../images/gm-banner.jpg";
import TeamBannerTwo from "../../images/team-banner-2.jpg"
import { Icon } from "@iconify/react";

import "../AboutUs/AboutUs.css";

const AboutUs = () => {
  return (
    <Container>
      <section className="section-spacing">
        <div className="section-title">
          <h2 className="text-center">
            About <span className="title-highlight">Watani Auto</span>
          </h2>
          <p className="title-desc-primary text-center">
            Get to know about Watani Auto in Brief
          </p>
        </div>

        <Row className="align-items-center section-spacing">
          <Col md={12} lg={5}>
            <p className="detailPara">
              Watani Auto was established at 2022, with a big responsibility to
              convey the brand name into a fact as an Saudi National
              organization in order to support the vision of KSA 2030 vision
              witch we really believe in by hearts in NHC.
            </p>
            <p className="detailPara">
              NHC set a great growth plan for Watani Auto to be it is arm in the
              automotive distribution in the KSA market, several objective are
              there to place Watani auto as the 1st customer choice in KSA,
              market leader in the automotive sector, and main innovative
              contributor in the automotive industry by the few years.
            </p>
            <p className="detailPara">
              Watani Auto is fortunate cause of the support given by NHC that
              has the experience since 1940 as an extension of Alissa Group, NHC
              has decided to dedicate the support of the group companies to
              Watani Auto.
            </p>
          </Col>
          <Col md={12} lg={7}>
            <div className="border-shadow-radius">
              <img className="img-fluid" src={AboutIntroBanner} />
            </div>
          </Col>
        </Row>
      </section>

      <section className="section-spacing align-items-center">
        <div className="section-title">
          <h2 className="text-center">Man of the Vision</h2>
          <p className="title-desc-primary text-center">
            Know the Vision of Founder
          </p>
        </div>

        <Row className="align-items-center">
          <Col md={12} lg={3}>
            <div className="mb-2 border-shadow-radius">
              <img className="img-fluid mx-auto d-block" src={Owner} />
            </div>
          </Col>

          <Col md={12} lg={9}>
            <p className="detailPara">
              Mr. Najeeb Abdullatif Alissa business character and values of
              integrity, hard work and confidence inspired him to become the
              chairman of Alissa Automotive Group till 2016 introducing and
              managing the brands of GM, Nissan, and Isuzu.
            </p>
            <p className="detailPara">
              Mr. Najeeb Abdullatif Alissa is the group founder and chairman of
              NHC, whose inspiration continues to guide the success of NHC today
              leaving an imprint on the prosperous and powerful partnership
              Suzuki Automotive and Marine as well to build up Watani Auto and
              Watani Express.
            </p>

            <h2 className="ceoTitle">Najeeb Abdullatif Alissa</h2>
            <h4 className="designation">Founder & Chairman</h4>
            <h4 className="companyName">Najeeb Auto</h4>
          </Col>
        </Row>
      </section>

      <section className="section-spacing">
        <div className="watani-quote">
          <p>
            <Icon icon="mdi:format-quote-open" />
            Watani is most word always near to Saudi people hearts, cause we all
            believe of the country management and capabilities, so we found the
            most expressive word to express these believes is Watani. We promise
            to deliver the world class standards to KSA customers, relaying on
            our values. <Icon icon="mdi:format-quote-close" />
          </p>
        </div>
      </section>

      <section className="section-spacing">
        <Row>
          <Col md={12} lg={4}>
            <div className="values-wrap">
              <Icon className="vision-mission-icon" icon="ion:eye" />
              <h3>Vision</h3>
              <p>
                At Watani Auto, we believe that customer satisfaction is the
                main target.
              </p>
              <p>
                So we aspire to be the most competitive organization in the
                automotive industry in KSA market, providing world class
                customer experience and technology.
              </p>
            </div>
          </Col>
          <Col md={12} lg={4}>
            <div className="values-wrap">
              <Icon
                className="vision-mission-icon"
                icon="mingcute:target-fill"
              />
              <h3>Mission</h3>
              <p>
                Market high quality products and successful ownership deals to
                our customer, supported by excellent customer support to achieve
                market leadership in terms of customer satisfaction and loyalty.
              </p>
              <p>
                Our customers, employees and shareholders and business partners
                will share this success as well.
              </p>
            </div>
          </Col>
          <Col md={12} lg={4}>
            <div className="border-shadow-radius">
              <img className="img-fluid vmBanner" src={VisionMissionBanner} />
            </div>
          </Col>
        </Row>
      </section>

      <section className="section-spacing">
        <div className="section-title">
          <h2 className="text-center">Core Values</h2>
          <p className="title-desc-primary text-center">Values we believe in</p>
        </div>
        <Row className="mt-5">
          <Col sm={12} md={6} lg={4}>
            <div className="core-values-wrap">
              <Icon className="valuesIcon" icon="streamline:star-badge-solid" />
              <h3>Quality of achievement</h3>
              <p>
                We places a strong emphasis on quality of achievement as a core
                value. We believe that providing high-quality products and
                services is the foundation of building long-lasting
                relationships with our customers.
              </p>
            </div>
          </Col>

          <Col sm={12} md={6} lg={4}>
            <div className="core-values-wrap">
              <Icon className="valuesIcon" icon="vaadin:trophy" />
              <h3>Ambitious Achievers</h3>
              <p>
                We are a result driven organization, we set ambitious and
                challenging targets, and aspire to consistently achieve them in
                the best interest of the communities we serve.
              </p>
            </div>
          </Col>

          <Col sm={12} md={6} lg={4}>
            <div className="core-values-wrap">
              <Icon className="valuesIcon" icon="fluent:people-48-filled" />
              <h3>Our People</h3>
              <p>
                People are at the center of everything we do, from our employees
                to those who touch our business to the communities we call home.
              </p>
            </div>
          </Col>

          <Col sm={12} md={6} lg={4}>
            <div className="core-values-wrap">
              <Icon className="valuesIcon" icon="uis:graph-bar" />
              <h3>Mutual Benefit</h3>
              <p>
                People are at the center of everything we do, from our employees
                to those who touch our business to the communities we call home.
              </p>
            </div>
          </Col>

          <Col sm={12} md={6} lg={4}>
            <div className="core-values-wrap">
              <Icon
                className="valuesIcon"
                icon="pepicons-pencil:handshake-circle-filled"
              />
              <h3>Acting With Dignity And Respect</h3>
              <p>
                We believe that the cornerstone of our culture is to act and
                treat everyone with fairness and respect.
              </p>
            </div>
          </Col>

          <Col sm={12} md={6} lg={4}>
            <div className="core-values-wrap">
              <Icon className="valuesIcon" icon="game-icons:on-target" />
              <h3>Ambitio Achievers</h3>
              <p>
                We believe in the principle of mutual benefit, where all parties
                involved in any transaction or partnership should experience
                positive outcomes and growth. We strive to create win-win
                relationships with our clients, customers, employees, and
                partners.
              </p>
            </div>
          </Col>
        </Row>
      </section>

      <section className="section-spacing">
        <div class="section-title">
          <h2 class="text-center">
            <span class="title-highlight">Watani Auto</span> Team Management
          </h2>
          <p class="title-desc-primary text-center">Meet Our Team of Watani</p>
        </div>

        <Row className="mt-5">
          <Col md={12} lg={2}>
            <div className="border-shadow-radius">
              <img className="img-fluid" src={GeneralManager} />
            </div>
          </Col>
          <Col md={12} lg={6}>
            <p className="detailPara">
              Mr. Ahmed Joined Watani since January 2023, he has 25 years
              experience in Automotive industry regionally. Previously Ahmed was
              at the position of Managing Director at GB Auto, and Regional
              Sales Director. Under his leadership, we granted our success plans
              based on his achievement and commitment by implementing a new
              sales strategy and leading a highly motivated sales team to
              success.
            </p>
            <p className="detailPara">
              “We understand that realizing our dreams requires a combination of
              hard work, passion, and adaptability. We are constantly evolving,
              learning from our experiences, and seeking innovative solutions to
              bring our visions to life.”
            </p>
            <h2 className="ceoTitle">Mr. Ahmed El Baz</h2>
            <h4 className="designation">General Manager</h4>
            <h4 className="companyName">Watani Auto</h4>
          </Col>
          <Col md={12} lg={4}>
            <div className="border-shadow-radius">
                <img className="img-fluid" src={TeamBannerTwo}/>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default AboutUs;
