import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Icon } from "@iconify/react";

import "../CallToAction/CallToAction.css";

import Pana from "../../images/pana.png";

const CallToAction = () => {
  return (
    <section id="call-to-action" className="section-spacing-top light-blue-bg">
      <Container>
        <Row className="mt-5 align-items-center">
          <Col className="mb-2" sm={12} md={12} lg={8}>
            <div className="cta-content">
              <h2 className="cta-title">Introducing our online shop</h2>
              <h5 className="cta-subtitle">
                The first-ever complete online experience
              </h5>
              <p>
                Watani Auto team bilevels that world is constantly moving,
                transforming, and evolving. Change is inevitable and
                accelerating so Watani has worked to provide Integrated services
                that meets all customer segments needs.
              </p>
              <p>
                Currently Watani Auto is working on an E-commerce platform witch
                is designed according to world wide class standard, head to head
                with international brands, as we made sure of provide an easy
                use experience at Anytime and Anywhere across KSA, as well to
                make a win-win deals with every customer uses the platform.
              </p>
              <p>
                Watani Auto has a plan to launch an Authorized Service Center
                network in order to provide support to our customer next to the
                car delivery, as we consider customer centercity is our first
                priority, and it is one of our values to care of every single
                detail before and next to the vehicle delivery.
              </p>
              <p><strong>We have our dreams, some has came true, and soon rest will be also true...</strong></p>
              {/* <a className="brand-btn" href="#" target="_blank">Visit Online</a> */}
            </div>
          </Col>
          <Col className="mb-2" sm={12} md={12} lg={4}>
            <img src={Pana} className="img-fluid mx-auto d-block" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CallToAction;
