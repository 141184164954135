import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiSuzuki } from "@icons-pack/react-simple-icons";
import { SiHyundai } from "@icons-pack/react-simple-icons";
import { SiKia } from "@icons-pack/react-simple-icons";
import { SiToyota } from "@icons-pack/react-simple-icons";
import { SiChevrolet } from "@icons-pack/react-simple-icons";
import { SiMercedes } from "@icons-pack/react-simple-icons";

import "../OurBrands/OurBrands.css";

const OurBrands = () => {
  return (
    <section id="our-brands" className="section-spacing">
      <Container>
        <div className="section-title">
          <h2 className="text-center">Our Brands</h2>
          <p className="title-desc-primary text-center">
            We have been working with the top brands in the world
          </p>
        </div>
        <Row className="mt-5 text-center justify-content-center">
          <Col className="mb-2" sm={4} md={3} lg={2}>
            <SiSuzuki color="#2061A8" size={64} />
          </Col>
          <Col className="mb-2" sm={4} md={3} lg={2}>
            <SiHyundai color="#2061A8" size={64} />
          </Col>
          <Col className="mb-2" sm={4} md={3} lg={2}>
            <SiKia color="#2061A8" size={64} />
          </Col>
          <Col className="mb-2" sm={4} md={3} lg={2}>
            <SiToyota color="#2061A8" size={64} />
          </Col>
          <Col className="mb-2" sm={4} md={3} lg={2}>
            <SiChevrolet color="#2061A8" size={64} />
          </Col>
          <Col className="mb-" sm={4} md={3} lg={2}>
            <SiMercedes color="#2061A8" size={64} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OurBrands;
