import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Header/Header.css"

import { LanguageContext } from "../../LanguageContext";


import Logo from '../../images/Logo.png'
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

const Header = () => {

  const { language } = useContext(LanguageContext);
  const translations = language === "en" ? require("../../translations/en.json") : require("../../translations/ar.json");

  return (
    <Navbar expand="lg" className="brand-header">
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/">
          <img className="brand-logo" src={Logo}></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mx-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link as={NavLink} className="brandNavLink" to="/">{translations.header.Home}</Nav.Link>
            <Nav.Link as={NavLink} className="brandNavLink" to="about-us">About Us</Nav.Link>
            <Nav.Link href="#" className="brandNavLink">Our Brands</Nav.Link>
            
            <NavDropdown className="brandNavLink" title="Offers" id="navbarScrollingDropdown">
              <NavDropdown.Item as={NavLink} className="brandNavLink" to="all-offers">All Offers</NavDropdown.Item>
              {/* <NavDropdown.Item as={NavLink} className="brandNavLink" to="offer-details/foundationDay">Foundation Day</NavDropdown.Item> */}
              <NavDropdown.Item as={NavLink} className="brandNavLink" to="offer-details/ramadanOffer">Ramadan Buzz Offer</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#" className="brandNavLink">Showrooms</Nav.Link>
            <Nav.Link href="#" className="brandNavLink">Partners</Nav.Link>
            <Nav.Link href="#" className="brandNavLink">Contact Us</Nav.Link>
          </Nav>
          <Form className="d-flex">
            {/* <a className="brand-btn" href="#" target="_blank">Shop Online</a> */}
            {/* <LanguageSwitcher></LanguageSwitcher> */}
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
