import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Icon } from "@iconify/react";

import "../Footer/Footer.css";

import Logo from "../../images/Logo.png";

const Footer = () => {
  return (
    <footer className="section-spacing">
      <Container>
        <Row>
          <Col className="mb-2" sm={12} md={6} lg={5}>
            <div className="footer-logo">
              <img src={Logo} />
            </div>
            <div className="footer-credit">
              <p>Copyright &copy; 2024 Watani Auto</p>
              <p>All rights reserved</p>
            </div>
          </Col>
          <Col className="mb-2" sm={12} md={6} lg={2}>
            <div className="footer-title">
              <h4>Company</h4>
            </div>

            <ul className="footer-list">
              <li>
                <a href="#about-us">About Us</a>
              </li>
              <li>
                <a href="#our-brands">Our Brands</a>
              </li>
              <li>
                <a href="#our-brands">Offers</a>
              </li>
              <li>
                <a href="#our-brands">Showrooms</a>
              </li>
              <li>
                <a href="#our-brands">Our Partners</a>
              </li>
            </ul>
          </Col>

          <Col className="mb-2" sm={12} md={6} lg={2}>
            <div className="footer-title">
              <h4>Support</h4>
            </div>

            <ul className="footer-list">
              <li>
                <a href="#">Contact Us</a>
              </li>
              <li>
                <a href="#">Terms of Service</a>
              </li>
              <li>
                <a href="#">Legal</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Status</a>
              </li>
            </ul>
          </Col>

          <Col className="mb-2" sm={12} md={6} lg={3}>
            <div className="footer-title">
              <h4>Social Media</h4>
            </div>

            <ul className="social-media-list">
              <a href="#" target="_blank">
                <li>
                  <Icon icon="mdi:instagram" className="footerSocialIcon" />
                </li>
              </a>

              <a href="#" target="_blank">
                <li>
                  <Icon icon="flowbite:facebook-solid" className="footerSocialIcon" />
                </li>
              </a>

              <a href="#" target="_blank">
                <li>
                  <Icon icon="mdi:twitter" className="footerSocialIcon" />
                </li>
              </a>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
