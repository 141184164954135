import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OffersCardBanner1 from "../../images/foundation-day-offers-banner.png";
import OffersCardBanner2 from "../../images/ramadan-buzz-offers.png";

import "../AllOffers/AllOffers.css";
import { Link } from "react-router-dom";

const AllOffers = () => {
  const offers = [
    {
      id: "ramadanOffer",
      title: "Ramadan Buzz Offer",
      description: "Explore our Ramadan offers and get best deals",
      image:  OffersCardBanner2 ,
      validity: "15th of March 2024",
    },
  ];

  return (
    <Container>
      <div className="section-spacing text-center">
        <h2 className="page-title">Find your best offer</h2>
        <p className="title-desc-primary vertical-line">
          Check out all available offers
        </p>
      </div>

      <Row className="justify-content-center">
        {offers.map((offer, index) => (
          <Col key={offer.id} className="mb-2" sm={12} md={6} lg={4}>
            <div className="offersWrap">
              <img src={offer.image} />
              <div className="offersWrapContent">
                <h3>{offer.title}</h3>
                <p>{offer.description}</p>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <Link
                    to={`/offer-details/${offer.id}`}
                    className="offersLink brand-btn-white"
                  >
                    More Details
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default AllOffers;
