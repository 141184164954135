import React from 'react';
import Banner from '../../components/Banner/Banner';
import About from '../../components/About/About';
import OurBrands from '../../components/OurBrands/OurBrands';
import WhyChooseUs from '../../components/WhyChooseUs/WhyChooseUs';
import CallToAction from '../../components/CallToAction/CallToAction';

const Home = () => {
  return (
    <div>
       <Banner />
       <About />
       <OurBrands />
       <WhyChooseUs />
       <CallToAction />
    </div>
  ); 
};

export default Home;
