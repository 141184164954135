import React from "react";
import { useParams } from 'react-router-dom';


import "./OfferDetails.css";
import OffersCardBanner1 from "../../images/foundation-day-offers-banner.png";
import OffersCardBanner2 from "../../images/ramadan-buzz-offers.png";
import OfferForm from "../OfferForm/OfferForm";
 

const OfferDetails = () => {
  
  const { id } = useParams();

  // Define the offers
  const offers = [
    {
      id: "ramadanOffer",
      title: "Ramadan Buzz Offer",
      description: "Explore our Ramadan offers and get best deals",
      image: OffersCardBanner2,
      validity: "15th of March 2024",
    },
  ];

  // Find the offer with the matching id
  const offer = offers.find(offer => offer.id === id);

  // Check if the offer exists
  if (!offer) {
    return <div>Offer not found</div>;
  }

  return (
    <div className="offersBannerContent">
      <div className="offersBanner">
        <img src={offer.image} />
        <div className="offersBannerCaption">
            <h1>{offer.title}</h1>
            <h1>2024 Offer</h1>
            <p>Offer valid till {offer.validity}</p>
        </div>
      </div>
      <OfferForm/>
    </div>
  );
};

export default OfferDetails;
